/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, getTextLst, format } from "@/assets/app.js";
export default {
  name: "lcfqYyqy",
  components: {
    LCFQheader
  },
  data() {
    return {
      headTit: "新建申请",
      tit: "用印封存",
      oneft: "/index",
      userID: "",
      solId: "",
      actDefId: "",
      boDefId: "",
      formKey: "",
      //userSelect
      single: false,
      allowSelect: true,
      showUserDialog: false,
      showYzmc: false,
      showFcsj: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      yzmcList: [],
      yzList: [],
      data: {}
    };
  },
  mounted() {
    this.initBySolInstId();
    this.cgORxj();
  },
  methods: {
    async cgORxj() {
      let instId = this.$route.params.instId;
      // console.log(typeof(busad))
      if (instId != "") {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        let solId = this.$route.params.solId;
        var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids=";
        this.$ajax.post(url).then(res => {});
      } else {
        this.getUser();
      }
    },
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
      });
      var yzUrl = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqqyyz.do";
      this.$ajax.post(yzUrl).then(res => {
        this.yzList = res.data.data;
        this.yzList.map(item => {
          this.yzmcList.push(item.YZMC);
        });
      });
    },
    //获取用户信息
    getUser() {
      var userID = RxUtil.getCache("userID");
      this.data.yyr = RxUtil.getCache("userName");
    },
    //选人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    //下拉联动
    onYzmc(value) {
      this.data.sqyzmc_name = value;
      this.yzList.map(item => {
        if (value == item.YZMC) {
          this.data.fcks = item.SQBM;
        }
      });
      this.showYzmc = false;
    },
    onFcsj(timestamp) {
      this.data.fcsj = format(timestamp);
      this.showFcsj = false;
    },
    datas() {
      let sqbgr = JSON.parse(this.data.sqbgr);
      let sqfzr = JSON.parse(this.data.sqfzr);
      let jsbgr = JSON.parse(this.data.jsbgr);
      let jszfr = JSON.parse(this.data.jszfr);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            sqyzmc: this.data.fcks,
            sqyzmc_name: this.data.sqyzmc_name,
            fcks: this.data.fcks,
            fcsj: this.data.fcsj,
            fcyy: this.data.fcyy,
            sqbgr: sqbgr[0].id,
            sqbgr_name: sqbgr[0].text,
            sqfzr: sqfzr[0].id,
            sqfzr_name: sqfzr[0].text,
            jsbgr: jsbgr[0].id,
            jsbgr_name: jsbgr[0].text,
            jszfr: jszfr[0].id,
            jszfr_name: jszfr[0].text,
            bz: this.data.bz
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    onSubmit() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};